<template>
  <div class="modal-ads">
    <div class="bg" @click="selectClick"></div>
    {{ banners.length }}
    <div class="modal-banner" v-if="bannerImage">
      <div @click="clickBanner(i._id)" class="banner-image" v-for="(i, index) in banners" :key="index">
        <img :src="bannerImage[selectedIndex]" alt="ads banner" />
      </div>
      <button class="button primary" @click="selectClick">
        Close this Ads.
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps(['bannerData'])
import { useBannerStore } from '~/store/banner'
import useFetchCustom from '@/services/global-useFetch'
import { useCookieUtils } from '~/composables/useCookieUtils';
const bannerState = useBannerStore()
// set variables
const defaultLength = ref()
const banners = ref<any[]>([])
const bannerImage = ref<any>([])
const cookies = useCookieUtils('selectedIndex')
const selectedIndex = ref(0)
const config = useRuntimeConfig()

const selectClick = async () => {
  bannerState.PopUpModal(false)
}

// fetch banner
const fetchPopupBanner = async () => {
  try {
    const { data, error, statusCode } = await useFetchCustom(
      '/banner-slide-web'
    )
      .post({
        bannerType: 'Popup Banner'
      })
      .json()

    if (statusCode.value === 200 || statusCode.value === 202 || data.value) {
      const res:any = data.value
      banners.value = res.banner
      // console.log(banners.value)
      defaultLength.value = res.banner.length
      bannerState.PopUpLength = defaultLength.value
      bannerImage.value = res.banner.map((i: any) => i.image.src)
    }
  } catch (error) {
    console.error('Error fetching popup', error)
  }
}
const clickBanner = async (indexSelect:any) => {
  //set url and curID
  const index = banners.value.findIndex((i:any)=>i._id = indexSelect)
  // console.log("check index", index)
  const curId = banners.value[index]._id
  const url = banners.value[index].url
  // console.log("check link", url)
  if (url) {
    try {
      await useFetchCustom('/banner-click-web').post({
        bannerId: curId
      })
    } catch (e) {
      console.error('Error adding click')
    }
    window.open(url, '_bank')
    bannerState.PopUpModal(false)
    return
  }
}
// Helper function to check if a string is valid JSON
const isValidJSON = (str: string) => {
  try {
    JSON.parse(str)
    return true
  } catch {
    return false
  }
}

const created = async () => {
  await fetchPopupBanner()

  if (defaultLength.value) {
    bannerState.PopUpModal(true)

    const cookieValue = cookies.value
    let selectedBanner = null

    if (cookieValue && isValidJSON(cookieValue)) {
      selectedBanner = JSON.parse(cookieValue)
    }

    if (selectedBanner && selectedBanner.selectedIndex !== undefined) {
      selectedIndex.value = selectedBanner.selectedIndex
    } else {
      selectedIndex.value = -1
    }

    selectedIndex.value += 1
    if (selectedIndex.value >= defaultLength.value) {
      selectedIndex.value = 0
    }

    cookies.value = JSON.stringify({ selectedIndex: selectedIndex.value })
  }
}

// Fetch banners on component mount
created()


</script>
<style lang="scss" scoped>
.modal-ads {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  padding: 20px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 888;

  .bg {
    background-color: rgba(#000, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 888;
  }

  .modal-banner {
    z-index: 999;
    position: relative;
    animation: slidedown 0.3s ease-in-out;

    @keyframes slidedown {
      from {
        transform: translateY(-100px);
      }

      to {
        transform: translateY(0);
      }
    }

    .banner-image {
      display: block;
      position: relative;

      img {
        max-width: 90vw;
        max-height: 70vh;
        //height: 100%;
        display: block;
        cursor: pointer;
      }
    }

    .button {
      margin-top: 5px;
      font-size: 1rem;

      @include mobile {
        font-size: 0.8rem;
      }
    }
  }
}
</style>
